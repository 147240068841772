@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

html {
  height: 100%;
  min-height: -webkit-fill-available;
}
body {
  height: 100%;
  min-height: 100%;
}
#root {
  height: 100%;
  min-height: 100%;
}

.hide-scroll::-webkit-scrollbar {
  width: 0px; /* For Chrome, Safari, and Opera */
}
.hide-scroll {
  scrollbar-width: none; /* For Firefox */
  -ms-overflow-style: none; /* For Internet Explorer and Edge */
}
